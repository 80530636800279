.discover {
    display: flex;
    height: 582px;
    margin-bottom: 150px;
    background: url('../../images/discover.png') no-repeat center;
}

.discoverContainer {
    display: flex;
    width: 1110px;
    margin: 0 auto;
}

/*Content*/
.content {
    position: relative;
    margin-top: 100px;
}

.content:after {
    content: '';
    display: block;
    position: absolute;
    top: 57px;
    left: 420px;
    width: 258px;
    height: 555px;
    background: url('../../images/discover_after_banner.png') no-repeat 100%;
    z-index: 1;
}

.description {
    max-width: 362px;
    width: 100%;
    margin: 12px 0 36px;
}

.descriptionAdaptive {
    display: none;
}

.quizCard {
    display: block;
    overflow: hidden;
    position: relative;
    max-width: 380px;
    width: 100%;
    margin-top: 95px;
    padding: 24px;
    background: var(--primary-button-blue);
    border-radius: 14px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.13);
}

.quizCard:after {
    content: '';
    position: absolute;
    top: 42px;
    right: 0;
    width: 138px;
    height: 146px;
    background: url('../../images/discount.svg') no-repeat center;
}

.quizCard:hover {
    cursor: pointer;
}

.quizCard h5 {
    color: var(--primary-white);
}

.quizCardText {
    color: var(--primary-white);
    max-width: 190px;
    width: 100%;
    margin: 8px 0 16px;
}

.quizButton {
    display: flex;
    align-items: center;
    color: var(--primary-white);
    font-family: 'PT Root UI', serif;
    font-weight: 700;
    line-height: 24px;
}

.quizButton svg {
    width: 24px;
    height: 24px;
    margin-left: 2px;
    fill: var(--primary-white);
}

/*Banner*/
.banner {
    width: 100%;
    height: 638px;
    margin-top: 32px;
    background: url('../../images/discover_banner.png') no-repeat right;
}

.bannerImage,
.adaptiveTitle {
    display: none;
}

@media (max-width: 1140px) {
    .discover {
        margin-bottom: 0;
        padding: 16px 0;
    }

    .discoverContainer {
        align-items: center;
        width: 100%;
        padding: 0 15px;
    }

    .discover {
        height: auto;
    }

    .content {
        margin-top: 0;
    }

    .content:after {
        display: none;
    }

    .quizCard {
        margin-top: 32px;
    }

    .banner {
        display: flex;
        flex-shrink: 0;
        width: 330px;
        height: 430px;
        margin-top: 0;
        background: none;
    }

    .bannerImage {
        display: flex;
        width: 100%;
        height: 100%;
    }
}

@media (max-width: 980px) {
    .discover {
        height: auto;
        padding: 0;
        background: var(--secondary-magnolia);
    }

    .discoverContainer {
        flex-direction: column;
        padding: 0;
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        order: 2;
        width: 100%;
        padding: 0 15px;
        background: var(--primary-white);
    }

    .content h2,
    .description {
        display: none;
    }

    .descriptionAdaptive {
        display: flex;
        text-align: center;
        max-width: 270px;
        width: 100%;
        margin: 24px auto 32px;
    }

    .quizCard {
        width: 100%;
        padding: 15px;
    }

    .quizCard:after {
        top: 50px;
        width: 120px;
        height: 100px;
        background: url('../../images/discount_adaptive.svg') no-repeat center;
    }

    .quizCardText {
        margin: 8px 0 12px;
        font-size: 14px;
        line-height: 16px;
    }

    .quizButton {
        font-size: 14px;
    }

    .banner {
        justify-content: center;
        order: 1;
        margin: 0 15px;
    }

    .adaptiveTitle {
        display: block;
        order: 0;
        margin-top: 40px;
        padding: 0 15px;
    }
}

@media (max-width: 360px) {
    .banner {
        width: 100%;
        margin: 0 auto;
        padding: 0 15px;
    }
}


















