.becomeGuide {
    padding-top: 40px;
}

.becomeGuideContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

/*Become Guid Left*/
.becomeGuideLeft {
    position: relative;
}

.becomeGuideLeftButton {
    position: absolute;
    top: 37px;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 68px;
    height: 68px;
    background: var(--primary-button-blue);
    border: none;
    border-radius: 100%;
    cursor: pointer;
}

.becomeGuideLeftButton:hover:after {
    content: '';
    position: absolute;
    top: 27.58%;
    right: 11.54%;
    bottom: 0;
    left: 7.69%;
    background: linear-gradient(0deg, var(--primary-button-blue), var(--primary-button-blue));
    opacity: 0.5;
    filter: blur(20.5128px);
}

.becomeGuideLeftButtonPlus {
    width: 24px;
    height: 24px;
    fill: var(--primary-white);
    z-index: 1;
}

.becomeGuideImage {
    width: 478px;
    height: 488px;
}

.leaderBoardImage {
    position: absolute;
    left: 9px;
    bottom: 17px;
    width: 192px;
    height: 146px;
    box-shadow: 0 2.35131px 41.0112px rgba(0, 0, 0, 0.13);
    border-radius: 8.22959px;
}

.becomeGuideTab {
    position: absolute;
    right: -10px;
    bottom: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 218px;
    height: 88px;
    padding: 0 16px;
    background: var(--primary-button-blue);
    border-radius: 12px 12px 12px 0;
    box-shadow: 0 3.75655px 3.75655px rgba(0, 0, 0, 0.15);
    filter: drop-shadow(0px 4.5641px 40px rgba(0, 0, 0, 0.12));
}

.becomeGuideTab > p {
    color: var(--primary-white);
}

.becomeGuideTabTitle {
    margin-bottom: 5px;
    color: var(--primary-white);
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    letter-spacing: 0.18px;
}

/*Become Guid Right*/
.preTitle {
    margin-bottom: 12px;
    color: var(--primary-button-blue);
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    text-transform: uppercase;
}

.becomeGuideText {
    max-width: 440px;
    width: 100%;
    margin: 24px 0;
}

.becomeGuideList {
    display: flex;
    margin-bottom: 40px;
}

.becomeGuideItem {
    display: flex;
    flex-direction: column;
    width: 160px;
    margin-right: 30px;
}

.becomeGuideItem:last-child {
    margin-right: 0;
}

.becomeGuideItemImage {
    width: 65px;
    height: 65px;
}

.becomeGuideItemTitle {
    font-size: 24px;
    font-family: 'PT Root UI', serif;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0.18px;
}

.becomeGuideLearMoreButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 40px;
    color: var(--primary-white);
    font-size: 14px;
    font-family: 'PT Root UI', serif;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: -0.08px;
    background: var(--primary-button-blue);
    border: none;
    border-radius: 12px;
    cursor: pointer;
}

@media (max-width: 1140px) {
    .becomeGuideLeftButton {
        top: 27px;
        width: 50px;
        height: 50px;
        background: linear-gradient(180deg, #00F0E0 0%, #00DCBE 100%);
    }

    .becomeGuideImage {
        width: 360px;
        height: 360px;
    }

    .leaderBoardImage {
        width: 168px;
        height: 128px;
        bottom: 0;
    }

    .becomeGuideTab {
        top: 0;
        right: 14px;
        bottom: unset;
        width: 142px;
        height: 58px;
        padding: 0 10px;
        filter: drop-shadow(0px 2.96303px 25.9681px rgba(0, 0, 0, 0.12));
    }

    .becomeGuideTab > p {
        font-size: 10px;
        line-height: 12px;
    }

    .becomeGuideTabTitle {
        margin-bottom: 3px;
        font-size: 14px;
        line-height: 16px;
    }
}

@media (max-width: 980px) {
    .becomeGuideContainer {
        flex-direction: column;
        justify-content: unset;
        align-items: unset;
    }

    .becomeGuideLeft {
        order: 1;
        margin: 0 auto;
    }

    .becomeGuideRight {
        order: 0;
        margin-bottom: 32px;
    }

    .preTitle {
        text-align: center;
    }

    .becomeGuideText {
        max-width: 100%;
    }

    .becomeGuideItem {
        width: auto;
    }

    .becomeGuideLearMoreButton {
        margin: 0 auto;
    }
}

@media (max-width: 768px) {
    .becomeGuideContainer {
        text-align: center;
    }

    .becomeGuideText {
        margin-bottom: 0;
    }

    .becomeGuideList,
    .becomeGuideLearMoreButton {
        display: none;
    }
}

@media (max-width: 480px) {
    .becomeGuideImage {
        width: 320px;
        height: 320px;
    }

    .becomeGuideTab {
        right: 0;
    }
}

@media (max-width: 360px) {
    .becomeGuideLeftButton {
        width: 40px;
        height: 40px;
    }

    .becomeGuideImage {
        width: 290px;
        height: 290px;
    }
}













