/*PT Root UI Light*/
@font-face {
    font-family: 'PT Root UI';
    src: url('../fonts/PTRootUI-Regular/PTRootUI-Regular.eot');
    src: url('../fonts/PTRootUI-Regular/PTRootUI-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/PTRootUI-Regular/PTRootUI-Regular.woff2') format('woff2'),
        url('../fonts/PTRootUI-Regular/PTRootUI-Regular.woff') format('woff'),
        url('../fonts/PTRootUI-Regular/PTRootUI-Regular.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

/*PT Root UI Regular*/
@font-face {
    font-family: 'PT Root UI';
    src: url('../fonts/PTRootUI-Regular/PTRootUI-Regular.eot');
    src: url('../fonts/PTRootUI-Regular/PTRootUI-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/PTRootUI-Regular/PTRootUI-Regular.woff2') format('woff2'),
        url('../fonts/PTRootUI-Regular/PTRootUI-Regular.woff') format('woff'),
        url('../fonts/PTRootUI-Regular/PTRootUI-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

/*PT Root UI Medium*/
@font-face {
    font-family: 'PT Root UI';
    src: url('../fonts/PTRootUI-Bold/PTRootUI-Bold.eot');
    src: url('../fonts/PTRootUI-Bold/PTRootUI-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/PTRootUI-Bold/PTRootUI-Bold.woff2') format('woff2'),
        url('../fonts/PTRootUI-Bold/PTRootUI-Bold.woff') format('woff'),
        url('../fonts/PTRootUI-Bold/PTRootUI-Bold.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

/*PT Root UI Bold*/
@font-face {
    font-family: 'PT Root UI';
    src: url('../fonts/PTRootUI-Bold/PTRootUI-Bold.eot');
    src: url('../fonts/PTRootUI-Bold/PTRootUI-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/PTRootUI-Bold/PTRootUI-Bold.woff2') format('woff2'),
        url('../fonts/PTRootUI-Bold/PTRootUI-Bold.woff') format('woff'),
        url('../fonts/PTRootUI-Bold/PTRootUI-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}