.videoBanner {
    padding-top: 40px;
}

.videoContainer {
    position: relative;
    width: 100%;
    height: 620px;
    background: var(--secondary-magnolia);
    border-radius: 20px;
}

.poster {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../images/video_banner.png');
    background-position: center;
    background-size: cover;
    border-radius: 20px;
    z-index: 1;
    cursor: pointer;
}

.posterText {
    position: absolute;
    max-width: 510px;
    width: 100%;
    bottom: 96px;
    left: 40px;
}

.posterTitle {
    color: var(--primary-white);
    font-size: 48px;
    font-family: 'PT Root UI', serif;
    font-weight: 700;
    line-height: 56px;
}

.posterDescription {
    margin-top: 30px;
    color: var(--primary-white);
    font-size: 34px;
    font-family: 'PT Root UI', serif;
    font-weight: 700;
    line-height: 36px;
}

.posterButton {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 92px;
    height: 92px;
    margin-top: -46px;
    margin-left: -46px;
    border: none;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 50%;
    filter: drop-shadow(0px 2.3px 11.5px rgba(0, 0, 0, 0.24));
    cursor: pointer;
}

.posterButton > svg {
    width: 32px;
    height: 36px;
}

.frame {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 20px;
}

@media (max-width: 1140px) {
    .posterText {
        bottom: 50px;
    }
}

@media (max-width: 980px) {
    .videoContainer {
        height: 520px;
    }

    .posterTitle {
        font-size: 44px;
        line-height: 52px;
    }

    .posterDescription {
        font-size: 30px;
        line-height: 32px;
    }
}

@media (max-width: 768px) {
    .videoContainer {
        height: 420px;
    }

    .posterText {
        max-width: 310px;
        left: 15px;
    }

    .posterTitle {
        font-size: 34px;
        line-height: 42px;
    }

    .posterDescription {
        margin-top: 15px;
        font-size: 20px;
        line-height: 22px;
    }
}

@media (max-width: 480px) {
    .videoContainer {
        height: 320px;
    }

    .posterButton {
        width: 48px;
        height: 48px;
        margin-top: -24px;
        margin-left: -24px;
    }

    .posterButton > svg {
        width: 16px;
        height: 18px;
    }

    .posterText {
        bottom: 20px;
    }

    .posterTitle {
        font-size: 24px;
        line-height: 28px;
    }

    .posterDescription {
        font-size: 18px;
        line-height: 20px;
    }
}

@media (max-width: 360px) {
    .videoContainer {
        height: 184px;
    }

    .posterText {
        max-width: 184px;
    }

    .posterTitle {
        font-size: 14px;
        line-height: 18px;
    }

    .posterDescription {
        margin-top: 8px;
        font-size: 10px;
        line-height: 12px;
    }
}