.overlay {
    overflow-y: scroll;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 6;
}

.modal {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 400px;
    background: var(--primary-white);
    border-radius: 14px;
    transform: translate(-50%, -50%);
    z-index: 7;
}

.modal.blind {
    filter: grayscale(90%);
}

.modal.success,
.modal.error {
    text-align: center;
    position: fixed;
    padding: 32px 24px 24px;
}

.modal.success > h2 {
    color: var(--secondary-robin-egg-blue);
}

.modal.error > h2 {
    color: var(--secondary-valentine-red);
}

.modalClose {
    position: absolute;
    top: 12px;
    right: 10px;
    width: 24px;
    height: 24px;
    fill: var(--primary-white);
    cursor: pointer;
}

.modalClose.success,
.modalClose.error {
    fill: var(--primary-midnight-green);
}

/*Modal Header*/
.modalHeader {
    padding: 24px;
    background: var(--primary-button-blue);
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
}

.modalTitle {
    color: var(--secondary-pastel-orange);
    font-size: 24px;
    font-family: 'PT Root UI', serif;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0.18px;
}

.modalDescription {
    margin-top: 10px;
    color: var(--primary-white);
}

.modalDescription > span {
    color: var(--primary-white);
    font-size: 20px;
    font-weight: 700;
}

/*Modal Body*/
.modalBody {
    padding: 24px;
    border-bottom: 1px solid var(--secondary-magnolia);
}

.answerList {
    margin: 30px 0;
}

.answerItem {
    list-style: inherit;
    list-style-position: inside;
    line-height: 22px;
}

.answerItem .checked {
    color: var(--primary-white);
    background: var(--primary-button-blue);
}

.answerButtons {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.answerItemButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 76px;
    height: 40px;
    font-family: 'PT Root UI', serif;
    font-weight: 700;
    letter-spacing: -0.08px;
    background: var(--secondary-magnolia);
    border: none;
    border-radius: 12px;
}

.answerItemButton.checked {
    color: var(--primary-white);
    background: var(--primary-button-blue);
}

.answerItemButton.success {
    background: var(--secondary-robin-egg-blue);
}

.answerItemButton.error {
    color: var(--primary-white);
    background: var(--secondary-valentine-red);
}


/*Modal Footer*/
.modalFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
}

.modalCount {
    color: var(--secondary-light-slate-gray);
}

.modalButton {
    width: 162px;
    height: 48px;
    color: var(--primary-white);
    background: linear-gradient(270deg, #00DCBE 0%, var(--primary-button-blue) 100%);
    border-radius: 12px;
    border: none;
    cursor: pointer;
}

.modalButton.disabled {
    background: var(--secondary-light-slate-gray);
}

.modalBackdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: var(--secondary-overlay);
}

.inputWrapper {
    position: relative;
    width: 100%;
    height: 48px;
    margin-top: 12px;
    line-height: 48px;
}

.inputWrapper.first {
    margin-bottom: 24px;
}

.inputField {
    width: 100%;
    height: 100%;
    padding: 0 10px;
    background: var(--secondary-magnolia);
    border: none;
    border-radius: 14px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.08);
    outline: none;
}

.inputField.error {
    border: 2px solid var(--secondary-valentine-red);
}

.inputField[type='email']::-webkit-input-placeholder {
    color: var(--secondary-light-slate-gray);
}

.inputField[type='email']::-moz-placeholder {
    color: var(--secondary-light-slate-gray);
}

.inputError {
    height: 16px;
    margin-top: 2px;
    color: var(--secondary-valentine-red);
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
}

.errorText {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
}

@media (max-width: 768px) {
    .modal.success,
    .modal.error {
        width: 200px;
    }

    .modalClose.success,
    .modalClose.error {
        top: 5px;
        right: 5px;
        fill: var(--primary-midnight-green);
    }

    .errorText {
        font-size: 14px;
        line-height: 16px;
    }
}

@media (max-width: 480px) {
    .modal {
        width: 96%;
    }

    .modalHeader,
    .modalBody,
    .modalFooter {
        padding: 12px;
    }

    .modalButton {
        width: 142px;
    }
}

@media (max-width: 360px) {
    .answerItemButton {
        width: 60px;
    }
}





















