.header {
    display: flex;
    align-items: center;
    width: 100%;
    height: 90px;
    background: var(--primary-white);
}

.headerInfo {
    display: flex;
    align-items: center;
    margin-left: auto;
}

@media (max-width: 1140px) {
    .header {
        position: fixed;
        height: 48px;
        border-bottom: 1px solid var(--secondary-magnolia);
        z-index: 5;
    }

    .headerInfo {
        display: none;
    }
}



