.faq {
    padding-top: 40px;
}

.faq h2 {
    text-align: center;
}

.faqContainer {
    width: 730px;
    margin: 0 auto;
}

.faqList {
    margin-top: 40px;
}

.faqItem {
    margin-bottom: 20px;
    background: var(--secondary-magnolia);
    border: 1px solid var(--secondary-magnolia);
    border-radius: 20px;
}

.faqItem:last-child {
    margin-bottom: 0;
}

.faqItemPreview {
    display: flex;
    padding: 32px;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.18px;
    cursor: pointer;
}

.faqItemToggle {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 30px;
    height: 30px;
    margin-right: 28px;
    background: linear-gradient(270deg, #00DCBE 0%, #2295EF 100%);
    border-radius: 90px;
}

.faqItemToggle.open {
    background: transparent;
    border: 2px solid var(--secondary-light-slate-gray);
}

.faqItemToggleIcon {
    width: 14px;
    height: 14px;
    fill: var(--primary-white);
}

.faqItemToggle.open .faqItemToggleIcon {
    fill: var(--secondary-light-slate-gray);
}

.faqItemDescriptionOpen {
    padding: 0 32px 32px 90px;
}

.faqItemDescriptionOpen > p {
    margin-bottom: 24px;
}

.faqItemDescriptionOpen > p:last-child {
    margin-bottom: 0;
}

.faqItemDescriptionOpen ul {
    margin-bottom: 24px;
}

.faqItemDescriptionOpen li {
    margin-bottom: 16px;
    list-style: disc inside;
}

.faqItemDescriptionOpen li:last-child {
    margin-bottom: 0;
}

@media (max-width: 480px) {
    .faqItemPreview {
        padding: 16px;
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
    }

    .faqItemToggle {
        margin-right: 16px;
    }

    .faqItemDescriptionOpen {
        padding: 0 16px 16px 62px;
    }

    .faqItemDescriptionOpen > p,
    .faqItemDescriptionOpen li {
        font-size: 14px;
        line-height: 16px;
    }

    .faqItemDescriptionOpen > p,
    .faqItemDescriptionOpen ul {
        margin-bottom: 16px;
    }

    .faqItemDescriptionOpen li {
        margin-bottom: 12px;
    }
}















