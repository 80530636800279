.whatTheySay {
    padding-top: 40px;
}

.whatTheySay h2 {
    text-align: center;
}

.preTitle {
    text-align: center;
    margin-bottom: 12px;
    color: var(--primary-button-blue);
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    text-transform: uppercase;
}

.subTitle {
    text-align: center;
    margin-top: 16px;
}

.reviewList {
    margin: 32px -15px 0;
}

.reviewItem {
    flex-shrink: 0;
    padding: 24px;
    background: var(--secondary-magnolia);
    border-radius: 20px;
}

.reviewTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.reviewTitle {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
}

.reviewContent {
    margin: 20px 0;
}

.reviewAuthor {
    display: flex;
}

.reviewAuthorImage {
    width: 52px;
    height: 52px;
    margin-right: 16px;
    border-radius: 100%;
    box-shadow: inset 0 10px 20px rgba(17, 70, 86, 0.15);
}

.authorInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.reviewAuthorName {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.18px;
}

.reviewAuthorPosition {
    color: var(--secondary-light-slate-gray)
}

.reviewList :global(.slick-slide) {
    padding: 0 15px;
}

.reviewList :global(li.slick-active) div {
    background-color: var(--primary-button-blue) !important;
}

@media (max-width: 768px) {
    .reviewList {
        margin-top: 20px;
    }
}

@media (max-width: 480px) {
    .reviewItem {
        padding: 16px;
    }

    .reviewTop {
        flex-direction: column;
        align-items: flex-start;
    }

    .reviewTitle {
        order: 1;
        line-height: 24px;
    }

    .reviewRate {
        order: 0;
        margin-bottom: 16px;
    }

    .reviewContent {
        margin: 16px 0;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.25px;
    }

    .reviewAuthorImage {
        width: 40px;
        height: 40px;
        margin-right: 12px;
    }

    .reviewAuthorName {
        font-size: 16px;
        line-height: 20px;
    }

    .reviewAuthorPosition {
        font-size: 14px;
        line-height: 16px;
    }
}

@media (max-width: 360px) {

}


















