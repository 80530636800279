.footer {
    margin-top: 40px;
    padding: 60px 0;
}

.footerInfo {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
}

.footerLeft {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.footerColumnItem {
    margin-top: 24px;
}

.footerToggleIcon {
    display: none;
}

.footerRight {
    display: flex;
    flex-direction: column;
    text-align: right;
    margin-left: 100px;
}

.copyright {
    margin-top: 75px;
    color: var(--secondary-light-slate-gray);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
}

@media (max-width: 1140px) {
    .footer {
        padding: 30px 0;
    }

    .footerInfo {
        flex-direction: column;
    }

    .footerColumnItem {
        margin-top: 20px;
    }

    .footerRight {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        width: 100%;
        margin: 32px 0 0 0;
    }

    .copyright {
        margin-top: 0;
    }
}

@media (max-width: 980px) {
    .footerInfo {
        margin-top: 0;
    }

    .footerLeft {
        flex-wrap: wrap;
        margin-top: 15px;
    }

    .footerColumn {
        width: 50%;
        padding: 15px 0;
    }
}

@media (max-width: 480px) {
    .footerColumn {
        width: 100%;
    }

    .footerColumnTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .footerColumnList {
        display: none;
    }

    .footerColumnListOpen {
        display: block;
    }

    .footerToggleIcon {
        display: flex;
        width: 14px;
        height: 14px;
        fill: var(--primary-midnight-green);
    }

    .footerRight {
        flex-direction: column;
        align-items: flex-start;
    }

    .copyright {
        margin-top: 32px;
    }
}