.menu {
    display: flex;
    align-items: center;
}

.menuItem {
    margin-right: 28px;
}

.menuItemLink {
    font-size: 13px;
    line-height: 16px;
    font-weight: 700;
    text-transform: uppercase;
}

.adaptiveMenu {
    flex-direction: column;
}

.adaptiveMenu .menuItem {
    margin: 0;
}

.adaptiveMenu .menuItemLink {
    display: block;
    padding: 12px 20px;
}