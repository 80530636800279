.adaptiveHeader {
    display: none;
}

.adaptiveHeaderButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    margin-left: 16px;
    border: 1.5px solid var(--secondary-magnolia);
    border-radius: 8px;
    cursor: pointer;
}

.overlay {
    overflow-y: scroll;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
}

@media (max-width: 1140px)  {
    .adaptiveHeader {
        display: flex;
        align-items: center;
        margin-left: auto;
    }

    .dropdownMenu {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 48px;
        right: 0;
        width: 100%;
        padding: 8px 0;
        background: var(--primary-white);
        border: 1.5px solid var(--secondary-magnolia);
        box-shadow: 0 6px 30px rgb(0 0 0 / 10%);
        z-index: 7;
    }
}