.calculateIncome {
    padding-top: 40px;
}

.rangeRow {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 32px;
}

.resultBox {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 40px;
}

.box {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    border-radius: 20px;
}

.estimatedBox {
    margin-right: 30px;
    background: linear-gradient(270deg, var(--secondary-robin-egg-blue) 0%, var(--primary-button-blue) 100%);
}

.monthlyBox {
    background: var(--secondary-magnolia);
}

.estimatedTitle, .monthlyTitle {
    margin-bottom: 24px;
}

.estimatedPrice, .monthlyPrice {
    font-size: 60px;
    font-family: 'PT Root UI', serif;
    font-weight: 700;
    line-height: 72px;
    letter-spacing: -0.5px;
}

.estimatedTitle, .estimatedPrice  {
    color: var(--primary-white);
}

.monthlyTitle, .monthlyPrice {
    color: var(--primary-midnight-green);
}

.afterCalculateText {
    text-align: center;
    width: 70%;
    margin: 24px auto 0;
    color: var(--secondary-light-slate-gray);
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.5px;
}

@media (max-width: 980px) {
    .estimatedPrice, .monthlyPrice {
        font-size: 52px;
        line-height: 64px;
    }
}

@media (max-width: 980px) {
    .calculateIncome h2 {
        margin-bottom: 10px;
    }

    .rangeRow {
        flex-direction: column;
        align-items: center;
        margin-top: 0;
    }

    .resultBox {
        margin-top: 30px;
    }
}

@media (max-width: 768px) {
    .estimatedBox {
        margin-right: 0;
        padding: 20px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    
    .monthlyBox {
        padding: 20px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .estimatedTitle, .monthlyTitle {
        margin-bottom: 12px;
    }

    .estimatedPrice, .monthlyPrice {
        font-size: 24px;
        line-height: 26px;
    }

    .afterCalculateText {
        width: 80%;
    }
}

@media (max-width: 480px) {
    .estimatedBox, .monthlyBox {
        padding: 10px;
    }

    .estimatedTitle, .monthlyTitle {
        font-size: 12px;
        line-height: 16px;
    }

    .afterCalculateText {
        width: 100%;
        margin-top: 20px;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.15px;
    }
}

@media (max-width: 360px) {
    .estimatedTitle, .monthlyTitle {
        max-width: 90px;
    }
}