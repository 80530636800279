.subscribe {
    margin: 50px 0;
}

.subscribeContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    padding: 30px 30px 30px 75px;
    background: var(--secondary-magnolia);
}

/*Subscribe Left*/
.subscribeLeft {
    max-width: 500px;
    width: 100%;
}

.preTitle {
    margin-bottom: 12px;
    color: var(--primary-button-blue);
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    text-transform: uppercase;
}

.subscribeTitle {
    font-size: 52px;
    font-family: 'PT Root UI', serif;
    font-weight: 700;
    line-height: 58px;
    letter-spacing: -0.5px;
}

.subscribeText {
    max-width: 440px;
    width: 100%;
    margin: 24px 0 42px;
}

.inputWrapper {
    position: relative;
    width: 450px;
    height: 50px;
    line-height: 50px;
}

.inputField {
    width: 100%;
    height: 100%;
    padding: 0 130px 0 30px;
    border: none;
    border-radius: 14px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.08);
    outline: none;
}

.inputField.error {
    border: 2px solid var(--secondary-valentine-red);
}

.inputField[type='email']::-webkit-input-placeholder {
    color: var(--secondary-light-slate-gray);
}

.inputField[type='email']::-moz-placeholder {
    color: var(--secondary-light-slate-gray);
}

.inputError {
    height: 16px;
    margin-top: 2px;
    color: var(--secondary-valentine-red);
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
}

.subscribeButton {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 112px;
    height: 40px;
    color: var(--primary-white);
    font-size: 14px;
    font-family: 'PT Root UI', serif;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: -0.08px;
    background: linear-gradient(270deg, #00DCBE 0%, #2295EF 100%);
    border: none;
    border-radius: 12px;
    cursor: pointer;
}

/*Subscribe Right*/
.subscribeRight {
    margin-left: -116px;
}

.subscribeImage {
    width: 612px;
    height: 372px;
}

@media (max-width: 1140px) {
    .subscribeContainer {
        align-items: center;
        padding: 30px;
    }

    .subscribeImage {
        width: 500px;
        height: 260px;
    }
}

@media (max-width: 980px) {
    .subscribeContainer {
        flex-direction: column;
        padding: 0;
        background: none;
    }

    .subscribeLeft {
        order: 1;
        max-width: 100%;
        margin-top: 24px;
    }

    .preTitle {
        text-align: center;
    }

    .subscribeTitle {
        text-align: center;
    }

    .subscribeText {
        max-width: 100%;
        margin: 12px 0 24px;
    }

    .inputWrapper {
        margin: 0 auto;
    }

    .inputField {
        background: var(--secondary-magnolia);
    }

    .subscribeRight {
        order: 0;
        margin-left: 0;
    }
}

@media (max-width: 768px) {
    .subscribeTitle {
        font-size: 34px;
        line-height: 36px;
    }

    .subscribeText {
        text-align: center;
    }

    .subscribeImage {
        width: 100%;
        height: auto;
    }
}

@media (max-width: 480px) {
    .inputWrapper {
        width: 100%;
    }

    .inputField {
        padding-left: 10px;
    }

    .subscribeButton {
        width: 80px;
    }
}