.mission {
    padding-top: 40px;
}

.missionContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 30px 20px 0 60px;
    background: var(--secondary-magnolia);
    border-radius: 20px;
}

.missionContent {
    max-width: 420px;
    width: 100%;
}

.missionContent p {
    margin-bottom: 24px;
}

.missionContent p:last-child {
    margin-bottom: 0;
}

.missionText {
    margin-top: 24px;
}

.missionImage {
    width: 602px;
    height: 452px;
}

@media (max-width: 1140px) {
    .missionContainer {
        padding: 30px;
    }

    .missionImage {
        width: 502px;
        height: 352px;
    }
}

@media (max-width: 980px) {
    .missionContainer {
        flex-direction: column;
    }

    .missionContent {
        max-width: 100%;
        margin-bottom: 30px;
    }

    .missionImage {
        width: 324px;
        height: 262px;
    }
}

@media (max-width: 768px) {
    .missionText {
        text-align: center;
    }
}

@media (max-width: 360px) {
    .missionImage {
        width: 100%;
        height: auto;
    }
}