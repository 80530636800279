.joinUs {
    padding-top: 40px;
}

.joinUsContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 60px;
    background: var(--secondary-magnolia);
    border-radius: 20px;
}

.joinUsContainer h2 {
    display: none;
}

.joinUsItem {
    display: flex;
    flex-direction: column;
    margin-bottom: 45px;
}

.joinUsItem:last-child {
    margin-bottom: 0;
}

.joinUsItemNumber {
    font-size: 60px;
    font-family: 'PT Root UI', serif;
    font-weight: 700;
    line-height: 72px;
    letter-spacing: -0.5px;
}

.joinUsRight {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.subtitle {
    position: relative;
    margin-left: 65px;
    font-style: italic;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}

.subtitle:after {
    content: '';
    position: absolute;
    top: 12px;
    right: -70px;
    width: 62px;
    height: 46px;
    background: url('../../images/stroke_arrow.svg') no-repeat;
}

.joinUsButton {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 65px;
    right: 137px;
    width: 80px;
    height: 80px;
    background: var(--primary-button-blue);
    border-radius: 100%;
    cursor: pointer;
}

.joinUsButton:hover:after {
    content: '';
    position: absolute;
    top: 27.58%;
    right: 11.54%;
    bottom: 0;
    left: 7.69%;
    background: linear-gradient(0deg, var(--primary-button-blue), var(--primary-button-blue));
    opacity: 0.5;
    filter: blur(20.5128px);
}

.joinUsButtonPlus {
    width: 26px;
    height: 26px;
    fill: var(--primary-white);
    z-index: 1;
}

.joinUsImage {
    width: 600px;
    height: 500px;
    margin-top: 54px;
}

@media (max-width: 1140px) {
    .joinUsContainer {
        padding: 30px;
    }

    .joinUsItemNumber {
        font-size: 20px;
        line-height: 24px;
    }
}

@media (max-width: 980px) {
    .joinUsContainer {
        flex-direction: column;
        /*align-items: unset;*/
    }

    .joinUsContainer h2 {
        display: block;
        margin-bottom: 40px;
    }

    .joinUsList {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 330px;
        margin: 0 auto 24px;
    }

    .joinUsItem {
        text-align: center;
        margin-bottom: 0;
    }

    .joinUsItem:nth-child(odd) {
        align-self: flex-end;
    }

    .joinUsItem:nth-child(even) {
        align-self: flex-start;
    }
}

@media (max-width: 768px) {
    .subtitle {
        margin-right: 34px;
        margin-left: 0;
    }

    .joinUsButton {
        top: 60px;
        right: 54px;
        width: 40px;
        height: 40px;
    }

    .joinUsButtonPlus {
        width: 14px;
        height: 14px;
    }

    .joinUsImage {
        width: 294px;
        height: 248px;
        margin-top: 40px;
    }
}

@media (max-width: 480px) {
    .joinUsContainer {
        padding: 30px 15px;
    }

    .joinUsList {
        width: 100%;
    }
}

@media (max-width: 360px) {
    .joinUsItem {
        margin-bottom: 16px;
    }

    .subtitle {
        margin-right: 50px;
    }

    .joinUsButton {
        top: 59px;
        right: 46px;
        width: 34px;
        height: 34px;
    }

    .joinUsImage {
        width: 256px;
        height: 210px;
    }
}