.guideForm {
    padding-top: 40px;
}

.guideFormContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.guideFormImage {
    width: 350px;
    height: 336px;
}

.guideFormInfoAdaptive {
    display: none;
}

.guideFormWrapper {
    flex-shrink: 0;
    width: 728px;
    height: 408px;
    margin-left: 32px;
    padding: 60px;
    background: url('../../../images/guideForm/guide_form_bg.png') no-repeat 100%;
    border-radius: 20px;
}

.preTitle {
    margin-bottom: 12px;
    color: var(--primary-white);
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    text-transform: uppercase;
}

.guideFormTitle {
    margin-bottom: 16px;
    font-size: 52px;
    font-family: 'PT Root UI', serif;
    font-weight: 700;
    line-height: 58px;
}

.subTitleAdaptive {
    display: none;
}

.guideFormRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 32px;
}

.inputWrapper {
    position: relative;
    width: 290px;
    height: 50px;
    line-height: 50px;
}

.inputField {
    width: 100%;
    height: 100%;
    padding: 0 30px;
    border: none;
    border-radius: 14px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.08);
    outline: none;
}

.inputField.error {
    border: 2px solid var(--secondary-valentine-red);
}

.inputField[type='email']::-webkit-input-placeholder {
    color: var(--secondary-light-slate-gray);
}

.inputField[type='email']::-moz-placeholder {
    color: var(--secondary-light-slate-gray);
}

.inputError {
    height: 16px;
    margin-top: 2px;
    color: var(--secondary-valentine-red);
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
}

.guideFormButton {
    width: 222px;
    height: 50px;
    color: var(--primary-white);
    font-size: 14px;
    font-family: 'PT Root UI', serif;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: -0.08px;
    background: linear-gradient(270deg, #00DCBE 0%, #2295EF 100%);
    border: none;
    border-radius: 12px;
    cursor: pointer;
}

.guideFormInfo {
    max-width: 285px;
    width: 100%;
    color: var(--primary-white);
    font-family: 'PT Root UI', serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0.18px;
}

@media (max-width: 1140px) {
    .guideFormImage {
        width: auto;
        height: auto;
    }
}

@media (max-width: 980px) {
    .guideFormContainer {
        flex-direction: column;
    }

    .guideFormWrapper {
        width: 100%;
        height: auto;
        margin-left: 0;
        padding: 0;
        background: none;
    }

    .preTitle {
        margin-top: 32px;
        text-align: center;
        background: linear-gradient(270deg, #00DCBE 0%, #2295EF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .guideFormTitle {
        text-align: center;
    }

    .subTitle {
        display: none;
    }

    .subTitleAdaptive {
        display: flex;
        justify-content: center;
        text-align: center;
    }

    .guideFormRow {
        flex-direction: column;
        margin-top: 24px;
    }

    .inputWrapper {
        width: 450px;
    }

    .inputWrapper:first-child {
        margin-bottom: 24px;
    }

    .inputField {
        background: var(--secondary-magnolia);
    }

    .guideFormInfo {
        text-align: center;
        max-width: 100%;
        margin-top: 24px;
        color: var(--primary-midnight-green);
        font-size: 20px;
        line-height: 24px;
    }
}

@media (max-width: 768px) {
    .guideFormTitle {
        font-size: 34px;
        line-height: 36px;
    }
}

@media (max-width: 480px) {
    .guideFormImage {
        width: 215px;
    }

    .guideFormInfoAdaptive {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 328px;
        height: 134px;
        margin-top: 16px;
        padding: 0 16px;
        color: var(--primary-white);
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0.18px;
        background: url('../../../images/guideForm/guide_form_info_bg.png') no-repeat center;
    }

    .inputWrapper {
        width: 100%;
    }

    .guideFormInfo {
        display: none;
    }
}

@media (max-width: 360px) {
    .guideFormInfoAdaptive {
        width: 280px;
        background-size: 100%;
    }

    .inputField {
        padding: 0 10px;
    }
}














