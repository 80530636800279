.slider {
    width: 476px;
}

.card {
    position: relative;
    height: 726px;
    outline: none;
}

.bg {
    position: absolute;
    top: 50%;
    right: 0;
    width: 476px;
    height: 476px;
    margin-top: -238px;
}

.phone {
    position: absolute;
    top: 0;
    left: 50%;
    width: 336px;
    height: 726px;
    margin-left: -168px;
    z-index: 1;
}

@media (max-width: 1140px) {
    .slider {
        width: 300px;
    }

    .card {
        height: 458px;
        margin: 0 auto;
    }

    .bg {
        width: 300px;
        height: 300px;
        margin-top: -150px;
    }

    .phone {
        width: 212px;
        height: 458px;
        margin-left: -106px;
    }
}

@media (max-width: 980px) {
    .slider {
        flex-shrink: 0;
    }
}