* {
    margin: 0;
    padding: 0;
    color: var(--primary-midnight-green);
    font-size: 16px;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.5px;
    box-sizing: border-box;
}

html {
    /*Primary colors*/
    --primary-midnight-green: #114656;
    --primary-button-blue: #2295EF;
    --primary-white: #FFF;
    /*Secondary colors*/
    --secondary-gradient: linear-gradient(0deg, rgba(34,149,239,1) 0%, rgba(1,217,192,1) 0%);
    --secondary-robin-egg-blue: #01D9C0;
    --secondary-pastel-orange: #FFC046;
    --secondary-magnolia: #F4F5FB;
    --secondary-light-slate-gray: #78859F;
    --secondary-valentine-red: #EB5757;
    --secondary-overlay: rgba(0, 0, 0, 0.8);
}

ul, li {
    list-style: none;
}

a {
    text-decoration: none;
}

h1 {
    font-size: 96px;
    font-family: 'PT Root UI', serif;
    line-height: 112px;
    letter-spacing: -1.5px;
}

h2 {
    font-size: 60px;
    font-family: 'PT Root UI', serif;
    font-weight: 700;
    line-height: 72px;
    letter-spacing: -0.5px;
}

h2 span {
    color: var(--primary-button-blue);
    font-size: 60px;
    font-family: 'PT Root UI', serif;
    font-weight: 700;
    line-height: 72px;
    letter-spacing: -0.5px;
}

h3 {
    font-size: 48px;
    font-family: 'PT Root UI', serif;
    font-weight: 400;
    line-height: 56px;
}

h4 {
    font-size: 34px;
    font-family: 'PT Root UI', serif;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: normal;
}

h5 {
    font-size: 24px;
    font-family: 'PT Root UI', serif;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0.18px;
}

h6 {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.18px;
    font-feature-settings: 'liga' off;
}

@media (max-width: 1140px) {
    .main {
        padding-top: 48px;
    }
}

@media (max-width: 980px) {
    h2 {
        text-align: center;
    }
}

@media (max-width: 768px) {
    h2, h2 span {
        font-size: 34px;
        line-height: 36px;
    }
}