.howItWorks {
    padding-top: 40px;
}

.howItWorksContainer {
    display: flex;
    align-items: center;
    width: 100%;
}

.rightText {
    display: flex;
    flex-direction: column;
    margin-left: 100px;
}

.tabsBox {
    margin-top: 40px;
}

.card,
.cardActive {
    position: relative;
    padding: 15px 0 15px 30px;
    cursor: pointer;
}

.cardActive::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 4px;
    height: 80px;
    margin-top: -40px;
    background-color: var(--primary-button-blue);
}

.title,
.titleActive,
.titleActiveGradient {
    margin-bottom: 12px;
    font-size: 24px;
    font-family: 'PT Root UI', serif;
    font-weight: 700;
    line-height: 26px;
}

.titleActive {
    color: var(--primary-button-blue);
}

.titleBlue {
    color: var(--primary-midnight-green);
}

.titleGradient {
   color: var(--primary-midnight-green);
}

.titleActiveGradient {
    background: linear-gradient(270deg, var(--secondary-robin-egg-blue) 0%, var(--primary-button-blue) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@media (max-width: 980px) {
    .rightText {
        margin-left: 30px;
    }

    .tabsBox {
        margin-top: 20px;
    }

    .title,
    .titleActive,
    .titleActiveGradient {
        font-size: 20px;
        line-height: 24px;
    }

    .card,
    .cardActive {
        padding: 15px 0 15px 15px;
        cursor: pointer;
    }
}

@media (max-width: 768px) {
    .howItWorksContainer {
        flex-direction: column;
    }

    .rightText {
        text-align: center;
        width: 100%;
        margin: 0 auto;
    }

    .card {
        display: none;
    }

    .cardActive {
        padding: 0;
    }

    .cardActive::before {
        visibility: hidden;
    }

    .dotsBox {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }
    
    .dotsLine {
        cursor: pointer;
        width: 50px;
        height: 4px;
        background-color: var(--secondary-magnolia);
        margin-left: 15px;
        margin-right: 15px;
    }

    .dotsLineActive {
        background-color: var(--primary-button-blue);   
    }

    .dotsLineActiveGuide {
        background-color: var(--secondary-robin-egg-blue);  
    }

    .titleBlue {
        color: var(--primary-button-blue);
    }

    .titleGuide {
        background: linear-gradient(270deg, var(--secondary-robin-egg-blue) 0%, var(--primary-button-blue) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .titleGradient {
        background: linear-gradient(270deg, var(--secondary-robin-egg-blue) 0%, var(--primary-button-blue) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .titleActiveGradient {
        margin-bottom: 12px;
        font-size: 24px;
        font-family: 'PT Root UI', serif;
        font-weight: 700;
        line-height: 26px;
    }
}