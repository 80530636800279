.whoWeAre {
    padding-top: 40px;
}

.whoWeAreTop {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 207px;
    margin-bottom: 24px;
    background: url('../../images/whoWeAre/who_we_are_bg.png') no-repeat center;
}

.whoWeAreTitle {
    text-align: center;
}

.whoWeAreList {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.whoWeArePerson {
    text-align: center;
}

.whoWeArePersonImage {
    width: 252px;
    height: 272px;
    background: url('../../images/whoWeAre/firstPersonBg.png') no-repeat bottom;
}

.whoWeArePersonImage.second {
    background: url('../../images/whoWeAre/secondPersonBg.png') no-repeat bottom;
}

.whoWeArePersonImage img {
    display: block;
}

.whoWeArePersonTitle {
    margin-top: 25px;
    color: var(--primary-button-blue);
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.18px;
}

.whoWeArePersonPosition {
    margin-top: 5px;
}

.whoWeAreContent {
    text-align: center;
    max-width: 540px;
    width: 100%;
}

.whoWeAreContent p {
    margin-bottom: 24px;
}

.whoWeAreContent p:last-child {
    margin-bottom: 0;
}

.showMoreButton {
    display: none;
}

@media (max-width: 1140px) {
    .whoWeArePersonImage,
    .whoWeArePersonImage.second {
        width: 152px;
        height: 156px;
        background-size: 100%;
    }

    .whoWeArePersonTitle {
        margin-top: 16px;
    }
}

@media (max-width: 980px) {
    .whoWeAreList {
        justify-content: center;
        flex-wrap: wrap;
    }

    .whoWeAreContent {
        order: 0;
        text-align: inherit;
        max-width: 100%;
        margin-bottom: 24px;
    }

    .whoWeArePerson {
        order: 1;
    }

    .whoWeArePerson:first-child {
        margin-right: 60px;
    }

    .whoWeAreContent {
        text-align: center;
    }
}

@media (max-width: 768px) {
    .whoWeAreTop {
        height: 90px;
        background-size: cover;
    }

    .whoWeArePersonTitle {
        font-size: 14px;
        line-height: 16px;
    }

    .whoWeArePersonPosition {
        margin-top: 0;
        font-size: 12px;
        line-height: 16px;
    }
}

@media (max-width: 480px) {
    .hiddenContent {
        display: none;
    }

    .hiddenContentOpen {
        display: block;
    }

    .showMoreButton {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 150px;
        height: 40px;
        margin: 0 auto;
        font-size: 14px;
        font-family: 'PT Root UI', serif;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: -0.08px;
        background: var(--primary-white);
        border: 2px solid var(--secondary-magnolia);
        border-radius: 12px;
    }

    .showMoreButtonHidden {
        display: none;
    }

    .whoWeArePerson:first-child {
        margin-right: 25px;
    }
}

@media (max-width: 360px) {
    .whoWeArePerson {
        width: 100%;
    }

    .whoWeArePerson:first-child {
        margin-right: 0;
    }

    .whoWeArePersonImage,
    .whoWeArePersonImage.second {
        margin: 0 auto;
    }
}













