.socialLinks {
    display: flex;
}

.socialLinks .socialLinksItem:first-child {
    margin-right: 24px;
}

.socialLinks.column {
    flex-direction: column;
    align-items: flex-end;
}

.socialLinks.column .socialLinksItem:first-child {
    margin-right: 0;
    margin-bottom: 24px;
}

/*.socialLinksItem > a {
    display: flex;
}

.socialLinksItem:first-child > a > svg {
    width: 135px;
    height: 40px;
}

.socialLinksItem:last-child > a > svg {
    width: 120px;
    height: 40px;
}*/

.socialLinksItem > div {
    display: flex;
    cursor: pointer;
}

.socialLinksItem:first-child > div > svg {
    width: 135px;
    height: 40px;
}

.socialLinksItem:last-child > div > svg {
    width: 120px;
    height: 40px;
}

@media (max-width: 1140px) {
    .socialLinks.column {
        flex-direction: row;
    }

    .socialLinks.column .socialLinksItem:first-child {
        margin: 0 24px 0 0;
    }
}