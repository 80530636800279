.benefits {
    padding-top: 40px;
}

.benefitsTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.preTitle {
    margin-bottom: 12px;
    color: var(--primary-button-blue);
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    text-transform: uppercase;
}

.subTitle {
    margin-top: 10px;
    font-size: 24px;
    font-family: 'PT Root UI', serif;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0.18px;
}

.benefitsImage {
    width: 190px;
    height: 182px;
}

.benefitsList {
    display: grid;
    grid-template-columns: repeat(auto-fill, 340px);
    grid-gap: 40px;
    justify-content: space-between;
    margin-top: 32px;
}

.benefitsItemImage {
    width: 65px;
    height: 65px;
}

.benefitsItemTitle {
    margin: 10px 0;
    font-size: 24px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0.18px;
}

.benefitsItemText {
    color: var(--secondary-light-slate-gray);
}

@media (max-width: 1140px) {
    .benefitsList {
        grid-template-columns: repeat(auto-fill, 290px);
        grid-gap: 32px;
    }
}

@media (max-width: 980px) {
    .benefitsTop {
        justify-content: center;
        text-align: center;
    }

    .benefitsImage {
        display: none;
    }

    .benefitsList {
        grid-template-columns: repeat(auto-fill, 330px);
        grid-gap: 32px;
    }
}

@media (max-width: 768px) {
    .subTitle {
        font-size: 16px;
        font-family: 'Lato', serif;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.5px;
    }

    .benefitsList {
        display: flex;
        flex-direction: column;
    }

    .benefitsItem {
        text-align: center;
    }

    .showMoreButton {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 150px;
        height: 40px;
        margin: 0 auto;
        font-size: 14px;
        font-family: 'PT Root UI', serif;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: -0.08px;
        background: var(--primary-white);
        border: 2px solid var(--secondary-magnolia);
        border-radius: 12px;
        cursor: pointer;
    }
}

@media (max-width: 480px) {
    .benefits {
        padding: 40px 0;
    }

    .benefitsTop {
        width: 270px;
        margin: 0 auto;
    }

    .benefitsItemTitle {
        font-size: 20px;
        line-height: 24px;
    }
}

















