.container {
    display: flex;
    width: 1110px;
    margin: 0 auto;
}

.container.column {
    flex-direction: column;
}

@media (max-width: 1140px) {
    .container {
        width: 100%;
        padding: 0 15px;
    }
}