.logo {
    display: flex;
    align-items: center;
}

.logo svg {
    width: 40px;
    height: 36px;
}

.logo span {
    margin-left: 12px;
    color: #2295EF;
    font-size: 30px;
    font-family: 'PT Root UI', serif;
    font-weight: 700;
    line-height: 34px;
}

@media (max-width: 480px) {
    .logo.header svg {
        width: 24px;
        height: 21px;
    }

    .logo.header span {
        font-size: 18px;
        line-height: 20px;
    }
}