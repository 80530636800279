.headerButtons {
    display: flex;
    align-items: center;
}

.buttonSwitch {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin-right: 20px;
    background: transparent;
    border: 1.5px solid var(--primary-midnight-green);
    border-radius: 12px;
    cursor: pointer;
}

.buttonInterested {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 135px;
    height: 40px;
    color: var(--primary-white);
    font-size: 14px;
    font-family: 'PT Root UI', serif;
    font-weight: 700;
    letter-spacing: -0.08px;
    background: var(--primary-button-blue);
    border-radius: 12px;
    border: none;
    cursor: pointer;
}

@media (max-width: 1140px) {
    .buttonSwitch {
        display: none;
    }

    .buttonInterested {
        width: 100%;
    }
}