.rangeContainer {
    width: 354px;
    margin: 0 36px 20px;
}

.label {
    color: var(--secondary-light-slate-gray);
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
}

.labelIsMobile {
    display: none;
}

.rangeBox {
    display: flex;
    width: 100%;
    margin-top: 10px;
}

.tooltip,
.tooltipMobile {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;
}

.tooltipMobile {
    display: none;
    margin-top: 0;
}

.tooltipInner {
    text-align: center;
    position: relative;
    width: 86px;
    height: 46px;
    background: var(--secondary-magnolia);
    border-radius: 30px;
}

.tooltipPrice {
    text-align: center;
    position: absolute;
    top: 11px;
    left: 50%;
    width: 86px;
    margin-left: -62px;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
}

.tooltipInput {
    text-align: center;
    position: absolute;
    top: 11px;
    left: 50%;
    width: 86px;
    margin-left: -43px;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    background: transparent;
    border: none;
    outline: none;
}

.tooltipInput.price {
    margin-left: -35px;
}

/* Chrome, Safari, Edge, Opera */
.tooltipInput::-webkit-outer-spin-button,
.tooltipInput::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.tooltipInput[type=number] {
    -moz-appearance: textfield;
}

.thumb {
    position: relative;
    height: 30px;
    width: 30px;
    margin-left: -16px;
    background: linear-gradient(270deg, #00DCBE 0%, var(--primary-button-blue) 100%);
    border: 4px solid var(--primary-white);
    box-shadow: 0 6px 32px rgba(0, 0, 0, 0.15);
    border-radius: 50px;
    z-index: 2 !important;
    outline: none;
}

.track {
    position: relative;
    height: 36px;
    display: flex;
    width: 100%;
    z-index: 2
}

.start {
    content: '';
    position: absolute;
    top: 50%;
    left: -16px;
    width: 30px;
    height: 30px;
    margin-top: -15px;
    background: linear-gradient(270deg, #00DCBE 0%, var(--primary-button-blue) 100%);
    border: 4px solid var(--primary-white);
    border-radius: 50px;
    z-index: 1;
}

.end {
    content: '';
    position: absolute;
    top: 50%;
    right: -16px;
    width: 22px;
    height: 22px;
    margin-top: -11px;
    background: var(--primary-white);
    border: 3px solid var(--secondary-magnolia);
    border-radius: 50%;
}

@media (max-width: 1140px) {
    .rangeContainer {
        width: 400px;
    }
}

@media (max-width: 980px) {
    .rangeContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        margin: 30px 16px 40px;
    }

    .tooltip,
    .label {
        display: none;
    }

    .tooltipMobile {
        display: flex;
    }

    .labelIsMobile {
        display: block;
        text-align: center;
        margin-top: 16px;
        color: var(--secondary-light-slate-gray);
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
    }
}

@media (max-width: 768px) {
    .rangeContainer {
        width: 80%;
    }
}